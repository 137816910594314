import { Avatar, Box, Text } from "@chakra-ui/react"
import React, { FunctionComponent } from "react"

type Props = {
  name: string
  image: string
  occupation: string
  second_occupation?: string
}

/**
 * メンバー名や画像を含めて入れると、その形でアイコン、名前、業務内容を入れて返却する
 */
export const MemberCard: FunctionComponent<Props> = props => {
  return (
    <Box m={2} p={2} textAlign="center">
      <Avatar size="xl" name={props.name} src={props.image} />
      <Text textAlign="center">{props.name}</Text>
      <Text textAlign="center" color="gray.400">
        {props.occupation}
        {props.second_occupation && (
          <>
            <br />
            {props.second_occupation}
          </>
        )}
      </Text>
    </Box>
  )
}
