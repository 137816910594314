import { withPrefix, Link } from "gatsby"
import React from "react"
import Slider from "react-slick"
import { Box } from "@chakra-ui/layout"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

/**
 * トップページ用のスライダー
 * 通信もこっちで行う（予定）
 */
export default function WorksSlider() {
  const settings = {
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const images = [
    withPrefix("/img/members/takex5g.jpg"),
    withPrefix("/img/members/M00_ribbit.jpg"),
    withPrefix("/img/members/kanzumeet.jpg"),
    withPrefix("/img/members/tako.jpg"),
  ]
  return (
    <Slider {...settings}>
      {images.map(img => (
        <Box p={2} as="a" href={img}>
          <img src={img} alt="picture" />
        </Box>
      ))}
    </Slider>
  )
}
