import React, { FunctionComponent } from "react"
import { Badge, Box, Heading, Tag, Text } from "@chakra-ui/react"

type Props = { blog?: boolean }
/**
 * セクションごとのタイトルを返却する
 * blogを指定するとsubカラーになる
 * @param props blog:boolean
 * @returns ヘッダアイコン
 */
export const SectionHeader: FunctionComponent<Props> = props => {
  return (
    <Box width="100%">
      <Box
        display="inline-block"
        borderRadius="md"
        bg={props.blog ? "brand.sub" : "brand.main"}
        color="white"
        px={2}
        py={0.5}
        m={2}
      >
        <Text
          color="white"
          fontWeight="semibold"
          fontSize="xl"
          fontFamily="Montserrat"
        >
          {props.children}
        </Text>
      </Box>
    </Box>
  )
}
