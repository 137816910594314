import React from "react"
import {
  Box,
  Heading,
  SkeletonCircle,
  SkeletonText,
  Text,
} from "@chakra-ui/react"
import FrontLayout from "../components/FrontLayout"
import Members from "../components/Member/Members"
import { SectionHeader } from "../components/Section/SectionHeader"
import WorksSlider from "../components/Works/Slider"
import { Blog } from "../components/Blog/top"

export default function Home() {
  return (
    <FrontLayout>
      <SectionHeader>About</SectionHeader>
      <Heading fontSize="6xl" fontWeight="bold" color="brand.main">
        Create a New origin
      </Heading>

      <SectionHeader>Works</SectionHeader>
      <WorksSlider />

      <SectionHeader>Crew Member</SectionHeader>
      <Members />

      <SectionHeader blog>Blog</SectionHeader>
      <Heading fontSize="6xl" fontWeight="bold">
        Nolvem Garage
      </Heading>
      <Blog />
    </FrontLayout>
  )
}
