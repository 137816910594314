import {
  Heading,
  Avatar,
  Box,
  Center,
  Image,
  Flex,
  Text,
  Stack,
  Button,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react"
import { withPrefix } from "gatsby"
import * as React from "react"
import { MemberCard } from "./MemberCard"

export default function Members() {
  return (
    <SimpleGrid columns={[2, null, null, 4]} spacing={2}>
      <MemberCard
        name="ゆうもや"
        occupation="Engineering"
        image={withPrefix("/img/members/takex5g.jpg")}
      />
      <MemberCard
        name="牛蛙"
        occupation="Graphic Art"
        image={withPrefix("/img/members/M00_ribbit.jpg")}
      />
      <MemberCard
        name="缶詰"
        occupation="Web Engineering"
        image={withPrefix("/img/members/kanzumeet.jpg")}
      />
      <MemberCard
        name="たこ"
        occupation="Design"
        second_occupation="Strategy planning"
        image={withPrefix("/img/members/tako.jpg")}
      />
    </SimpleGrid>
  )
}
